<template>
    <div>
        <div class="ticket__body">
            <div class="ticket__body--row-resource">
                <span>{{ entry.servizio.type }}</span>
                <span>
                    {{
                        entry.data.toLocaleDateString(lang, {
                            weekday: "short",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric"
                        })
                    }}
                </span>
            </div>
            <div class="ticket__body--row-time" v-if="!hideEndTimeFromSoftware">
                <span>
                    {{
                        entry.data.toLocaleTimeString(lang, {
                            hour: "2-digit",
                            minute: "2-digit"
                        })
                    }}
                </span>
                <span class="ticket__body--row-durata">
                    <hr />
                    <span class="badge badge-pill badge-color p-2 px-3">{{
                        secondsToString(durata)
                    }}</span>
                </span>
                <span>{{ endTime }}</span>
            </div>
        </div>
        <div class="ticket__body--user">
            <div
                class="d-flex font-weight-bold"
                :class="
                    showRisorsa
                        ? 'justify-content-between'
                        : 'justify-content-center'
                "
            >
                <span>Utente</span>

                <span v-if="showRisorsa">{{ $t("risorsa") }}</span>
            </div>
            <div
                class="ticket__body--row-user d-flex"
                :class="
                    showRisorsa
                        ? 'justify-content-between'
                        : 'justify-content-center'
                "
            >
                <span class="d-flex flex-column">
                    <span>{{ repeat.user_identifier }}</span>
                </span>
                <span v-if="showRisorsa" class="ticket__body--row">
                    {{ repeat.risorsa.resource_name }}
                </span>
            </div>
        </div>
        <div class="ticket__body--cta">
            <div class="ticket__body--cta-title d-flex justify-content-between">
                <div
                    class="d-flex justify-content-around align-items-center p-4  w-100 w-print-full"
                >
                    <div
                        class="d-flex flex-column justify-content-center align-items-center"
                    >
                        <e-link
                            :href="repeat.qr_code"
                            :fileName="
                                repeat.codice_prenotazione + '_qr_code.png'
                            "
                            withoutBackground
                            class="mb-2"
                        >
                            <img :src="repeat.qr_code" width="100px" />
                        </e-link>

                        <span class="success font-weight-bold">{{
                            repeat.codice_prenotazione
                        }}</span>
                    </div>
                    <div
                        class="d-none flex-column justify-content-center align-items-center p-2"
                    >
                        <g-wallet-icon
                            class="mb-2"
                            style="width : 120px; height: 34px"
                        />
                        <a-wallet-icon style="width: 120px; " />
                    </div>
                </div>
            </div>
            <div class="cta d-flex flex-column align-items-center no-print">
                <div
                    class="cta--secondarie d-flex justify-content-around w-100"
                >
                    <AddToCalendar :repeat="repeat" v-if="!kiosk" />
                    <Manage :repeat="repeat" :index="index" />
                </div>
                <div class="cta--principale w-100 mt-3" v-if="!kiosk">
                    <DownloadPdf :repeat="repeat" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { showsEndTime } from "../../mixins/showsEndTime.js";
import AddToCalendar from "./AddToCalendar.vue";
import DownloadPdf from "./DownloadPdf.vue";
import Manage from "./Manage.vue";
import GWalletIcon from "./GWalletIcon.vue";
import AWalletIcon from "./AWalletIcon.vue";

export default {
    name: "CustomContent",
    mixins: [showsEndTime],
    components: {
        AddToCalendar,
        DownloadPdf,
        Manage,
        GWalletIcon,
        AWalletIcon
    },
    props: {
        repeat: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    computed: {
        ...mapState({
            entry: state => state.Entry
        }),
        showRisorsa() {
            return process.env.VUE_APP_SHOW_RISORSA || false;
        }
    }
};
</script>

<style>
:root {
    --show-ticket-clips: "";
}
.cta--secondarie span {
    font-size: 14px;
    font-weight: lighter;
}
.cursor-pointer {
    cursor: pointer;
}
.text-color {
    color: var(--custom-color-0);
}
.pill-rounded {
    border-radius: 40px;
}
.ticket__body {
    padding: 30px 20px;

    position: relative;

    border: 2px solid var(--ticket-border-color);

    position: relative;
}

.ticket__body::before,
.ticket__body--user::before {
    content: var(--show-ticket-clips);
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: white;
    border: 2px solid var(--ticket-border-color);

    position: absolute;
    top: 100%;
    left: 0;
    transform: translate(-50%, -50%);
    z-index: 100;
}
.ticket__body::after,
.ticket__body--user::after {
    content: var(--show-ticket-clips);
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: white;
    border: 2px solid var(--ticket-border-color);

    position: absolute;
    top: 100%;
    right: 0;
    transform: translate(+50%, -50%);
    z-index: 100;
}

.ticket__body--user {
    padding: 30px 20px;

    position: relative;

    border: 2px solid var(--ticket-border-color);
    border-top: none;
}
.ticket__body--cta {
    box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;

    position: relative;

    border: 2px solid var(--ticket-border-color);
    border-top: none;
    border-radius: 0 0 var(--ticket-border-radius) var(--ticket-border-radius);
}

.ticket__body--row-resource,
.ticket__body--row-time,
.ticket__body--user--title {
    display: flex;
    justify-content: space-between;
}
.ticket__body--row-resource,
.ticket__body--user--title {
    font-weight: bold;
}
.badge-color {
    color: var(--custom-color-text-0);
    background-color: var(--custom-color-0);
    font-weight: lighter;
}
.ticket__body--row-durata {
    width: 80%;
    position: relative;
}
.ticket__body--row-durata span.badge-color {
    position: absolute;
    top: 0;
    margin-left: 50%;
    transform: translate(-50%);
}
.ticket__body--row-durata hr {
    border: 0;
    height: 2px;
    background: linear-gradient(to right, white, black, white);
}
.btn-color {
    color: var(--custom-color-text-0);
    background-color: var(--custom-color-0);
    margin: 3px;
}
.ticket__body,
.ticket__body--user {
    border-bottom: 2px dashed var(--ticket-border-color);
}
</style>
