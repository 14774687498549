<template>
    <span class="cursor-pointer">
        <a
            :href="route"
            target="_blank"
            style="text-decoration: none; color: inherit;"
        >
            <i class="far fa-edit text-color"></i>
            {{ $t("buttons.manage." + entry.servizio.reservation_type) }}
        </a>
    </span>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "Manage",
    props: {
        repeat: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    computed: {
        ...mapState({
            entry: state => state.Entry,
            datiUtente: state => state.Entry.datiUtente
        }),
        route() {
            return this.$router.resolve({
                name: "Show",
                params: {
                    ente: this.cliente.Codice,
                    codice: this.repeat.codice_prenotazione
                },
                query: {
                    primaria: this.repeat.user_primary
                }
            }).href;
        }
    }
};
</script>
