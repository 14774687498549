<template>
    <button
        @click="downloadPdf"
        @keyup.enter="downloadPdf"
        class="btn-color btn pill-rounded w-100"
    >
        {{ $t("download_pdf") }}
    </button>
</template>

<script>
export default {
    name: "DownloadPdf",
    props: {
        repeat: {
            type: Object,
            required: true
        }
    },
    methods: {
        downloadPdf() {
            window
                .open(
                    `${process.env.VUE_APP_SERVER_URL}/api/entry/pdf/${this.repeat.entry}`,
                    "_blank"
                )
                .focus();
        }
    }
};
</script>
