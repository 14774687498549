<template>
    <div class="ticket" :class="classes">
        <div class="ticket__id position-relative">
            <span>
                <span class="ticket__id--code ml-2 font-weight-bold">{{
                    repeat.codice_prenotazione
                }}</span>
            </span>
            <span
                class="no-print text-muted position-absolute"
                style="top:50%; left:50%; transform: translate(-50%,-50%);font-size : 10px; cursor: pointer"
            >
                {{$t('buttons.open_accordion')}}
            </span
            >
            <span
                @click.stop="printPDF"
                @keypress.enter="$emit('print')"
                class="no-print cursor-pointer"
            >
                <i class="fas fa-print"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Ticket",
    emits: [
        "print",
        "clicked"
    ],
    props: {
        repeat: {
            type: Object,
            required: true
        },
        classes: {
            type: Object
        },
        index: {
            type: Number,
            required: true
        }
    },
    computed: {
        url() {
            return `${process.env.VUE_APP_SERVER_URL}/api/entry/pdf/${this.repeat.entry}`;
        }
    },
    methods: {
        printPDF() {
            if (!this.kiosk) {
                this.printDesktop();
            } else this.printKiosk();
        },
        printKiosk() {
            this.$emit("clicked", this.index);
            this.$nextTick(() => {
                var content = this.$parent.$refs["repeat_" + this.index][0]
                    .innerHTML;
                let clonedDocument = document.documentElement.cloneNode(true);
                let firstDivInClone = clonedDocument.querySelector("div");
                firstDivInClone.innerHTML = content;

                var printWindow = window.open("", "");
                printWindow.document.write(clonedDocument.innerHTML);
                printWindow.document.close();
                setTimeout(function() {
                    printWindow.print();
                    printWindow.close();
                }, 1000);
            });
        },
        printDesktop() {
            // Controlla se l'iframe esiste già
            let iframe = document.getElementById("pdfFrame");
            if (!iframe) {
                // Crea l'iframe se non esiste
                iframe = document.createElement("iframe");
                iframe.id = "pdfFrame";
                iframe.style.width = "100%";
                iframe.style.height = "100vh";
                iframe.style.display = "none"; // Nascondi l'iframe fino a quando il PDF non è caricato
                document.body.appendChild(iframe);
            }

            fetch(this.url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.blob();
                })
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    iframe.src = url;
                    iframe.style.display = "block";
                    iframe.onload = () => {
                        iframe.contentWindow.print();
                    };
                    iframe.style.display = "none";
                })
                .catch(error => {
                    console.error(
                        "There has been a problem with your fetch operation:",
                        error
                    );
                });
        }
    }
};
</script>
<style>
.ticket {
    border-radius: var(--ticket-border-radius);
}

.ticket__id {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid var(--ticket-border-color);
    border-bottom: none;
    width: 100%;
    padding: 1rem;

    border-radius: var(--ticket-border-radius);
    color: var(--custom-text-color-300);
    background-color: var(--custom-color-300);

    transition: all 300ms;
    transition-timing-function: linear;
}

.active .ticket__id {
    border-radius: var(--ticket-border-radius) var(--ticket-border-radius) 0 0;
    transition: all 300ms;
    transition-timing-function: linear;
}

.ticket__id--label {
    font-weight: 100;
}
</style>
