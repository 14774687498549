<template>
    <accordion
        class="mx-md-5 print-full-h"
        :items="repeats"
        :keyName="'codice_prenotazione'"
        :refName="'repeat'"
    >
        <template v-slot:sr-title="{ item }">
            {{ item.codice_prenotazione }}
        </template>
        <template v-slot:title="{ item, classes, index, toggle }">
            <ticket
                :repeat="item"
                :classes="classes"
                :index="index"
                @clicked="toggle(index)"
            />
        </template>

        <template v-slot:body="{ item, index }">
            <CustomContent :repeat="item" :index="index" />
        </template>
    </accordion>
</template>

<script>
import Accordion from "../layout/Accordion.vue";
import Ticket from "../../components/Confermata/Ticket.vue";

import CustomContent from "../../components/Confermata/CustomContent.vue";

export default {
    name: "RiepilogoPrenotazioni",
    components: {
        Accordion,
        Ticket,
        CustomContent
    },
    props: {
        repeats: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            activeIndex: null
        };
    },
    mounted() {
        if (this.items.length === 1) {
            this.activeIndex = 0;
        }
        console.log(this.repeats);
        if (this.configs.portale_pubblico.kiosk_auto_print) {
            for (let i = 0; i <= this.repeats.length; i++) {
                console.log(i);
                this.print(i);
            }
        }
    },
    methods: {
        print(index) {
            this.activeIndex = index;
            this.$nextTick(() => {
                var content = this.$refs["item_" + index][0].innerHTML;
                let clonedDocument = document.documentElement.cloneNode(true);
                let firstDivInClone = clonedDocument.querySelector("div");
                firstDivInClone.innerHTML = content;

                var printWindow = window.open("", "");
                printWindow.document.write(clonedDocument.innerHTML);
                printWindow.document.close();
                setTimeout(function() {
                    printWindow.print();
                    printWindow.close();
                }, 1000);
            });
        }
    }
};
</script>

<style>
.accordion {
}
.card-header .btn {
    text-decoration: none;
    width: 100%;
    text-align: left;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
@media print {
    .print-full-h {
        height: 100vh; /* Imposta l'altezza al 100% della viewport quando si stampa */
    }
}
</style>
