<template>
    <div>
        <div>
            <div
                class="
                HEADER
                form-padding
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
                data-tour-step="12"
            >
                <div
                    class="
                  success
                  d-block
                  flex-column
                  justify-content-center
                  align-items-center
                  text-center
                  m-3
                "
                >
                    <img
                        src="../../assets/images/fatto.svg"
                        alt="prenotazione confermata"
                        width="100%"
                    />
                    <p class="m-3 h3">{{ $t("confirmed.done-label") }}</p>
                </div>

                <p
                    v-html="
                        $tc(
                            'confirmed.greeting-text.' +
                                entry.servizio.reservation_type,
                            retEntry.length
                        )
                    "
                ></p>
            </div>

            <RiepilogoPrenotazioni :repeats="retEntry" />

            <div class="d-flex justify-content-center">
                <div class="px-5 my-2">
                    <e-button
                        class="btn btn-lg btn-block btn-color"
                        @click="reset"
                        @keyup.enter="reset"
                        outlined
                    >
                        {{ $t("buttons.new-reservation") }}
                    </e-button>
                </div>
            </div>

            <feedback v-if="configs.portale_pubblico.feedback === 1">
                {{ $t("feedback.tasto") }}
            </feedback>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { publicPosition } from "../../mixins/publicPosition";
import RiepilogoPrenotazioni from "../../components/Confermata/RiepilogoPrenotazioni.vue";
import Ticket from "../../components/Confermata/Ticket.vue";
import CustomContent from "../../components/Confermata/CustomContent.vue";
//@group Form
export default {
    mixins: [publicPosition],
    components: {
        RiepilogoPrenotazioni
    },
    computed: {
        ...mapState({
            entry: state => state.Entry,
            retEntry: state => state.Entry.retEntry
        }),
        mappedEntry() {
            return this.retEntry.map(repeat => {
                return {
                    title: Ticket,
                    content: CustomContent,
                    titleProps: { repeat },
                    contentProps: { repeat }
                };
            });
        }
    },
    beforeRouteLeave(_, __, next) {
        this.resetEntry();
        next();
    },
    methods: {
        ...mapMutations({
            resetEntry: "Entry/RESET_ENTRY",
            setArea: "Ente/POPULATE_AREE"
        }),

        /**
         * @vuese
         * Resetta entry e aree e torna alla pagina di selezione Servizio Area
         */
        reset() {
            this.setArea(null);
            this.resetEntry();
            this.$router.push({ name: this.$route.meta.redirect });
        }
    }
};
</script>

<style>
.success {
    color: #00bfa6;
}

.new-reservation-cta {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

/* Custom dropdown */
.custom-dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 10px;
    overflow: hidden;
    background-color: #acacac;
    color: #fff;
    font-size: 12px;
    padding: 0.5rem;
    padding-right: 2.5em;
    border: 0;
    margin: 0;
    border-radius: 3px;
    text-indent: 0.01px;
    text-overflow: "";
}

.custom-dropdown::before,
.custom-dropdown::after {
    content: "";
    position: absolute;
    pointer-events: none;
}

.custom-dropdown::after {
    /*  Custom dropdown arrow */
    content: "\25BC";
    height: 1em;
    font-size: 0.625em;
    line-height: 1;
    right: 1.2em;
    top: 50%;
    margin-top: -0.5em;
}

.custom-dropdown::before {
    /*  Custom dropdown arrow cover */
    width: 2em;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 3px 3px 0;
}

.custom-dropdown::before {
    background-color: rgba(0, 0, 0, 0.15);
}

.custom-dropdown::after {
    color: rgba(0, 0, 0, 0.4);
}

.custom-dropdown + .calendar-options {
    position: absolute;
    width: 212px;
    transition: all 0.4s;
}

.custom-dropdown + .calendar-options div {
    width: 100%;
    background-color: #bbbaba;
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;
    top: -1px;
    position: relative;
}

.calendar-options div:not(:last-child) {
    border-bottom: 1px solid black;
}
.calendar-options div:last-child {
    border-radius: 0 0 15px 15px;
}

.big {
    font-size: 1.2em;
}

.opacity-0 {
    opacity: 0;
}

.border-success-custom {
    border: 5px solid #00bfa6;
    border-radius: 5px;
}

#feedbackMsg {
    min-height: 200px;
}
</style>
