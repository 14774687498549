<template>
    <img :src="icon"/>
</template>
<script>
export default {
    name : 'GWalletIcon',
    computed: {
        icon() {
            return require('../../assets/gpay/dark/it.svg');
        }
    },
}
</script>
