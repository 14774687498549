var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('accordion',{staticClass:"mx-md-5 print-full-h",attrs:{"items":_vm.repeats,"keyName":'codice_prenotazione',"refName":'repeat'},scopedSlots:_vm._u([{key:"sr-title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.codice_prenotazione)+" ")]}},{key:"title",fn:function(ref){
var item = ref.item;
var classes = ref.classes;
var index = ref.index;
var toggle = ref.toggle;
return [_c('ticket',{attrs:{"repeat":item,"classes":classes,"index":index},on:{"clicked":function($event){return toggle(index)}}})]}},{key:"body",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('CustomContent',{attrs:{"repeat":item,"index":index}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }