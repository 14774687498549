<template>
    <span v-click-outside="closeCalendarDropdow" tabindex="0">
        <span
            class="cursor-pointer"
            @click="showCalendarDropdown = !showCalendarDropdown"
            @keyup.enter="showCalendarDropdown = !showCalendarDropdown"
        >
            <i class="far fa-calendar-plus text-color"></i>

            {{ $t("buttons.calendar.label") }}
        </span>
        <div
            tabindex="0"
            class="calendar-options cursor-pointer"
            :class="{ 'd-none opacity-0': !showCalendarDropdown }"
        >
            <div
                data-calendar-type="ics"
                @click="downloadCalendar('ics')"
                @keyup.enter="changeTest('ics')"
                tabindex="0"
            >
                {{ $t("buttons.calendar.ics") }}
            </div>
            <div
                data-calendar-type="google"
                @click="downloadCalendar('google')"
                @keyup.enter="changeTest('google')"
                tabindex="0"
            >
                Google
            </div>
        </div>
    </span>
</template>

<script>
import { mapState } from "vuex";
import { google, outlook, office365, yahoo } from "calendar-link";
import { colors } from "../../mixins/colors.js";

export default {
    name: "AddToCalendar",
    mixins: [colors],
    props: {
        repeat: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showCalendarDropdown: false
        };
    },
    computed: {
        ...mapState({
            entry: state => state.Entry,
            datiUtente: state => state.Entry.datiUtente,
            form: state => state.Utils.form,
            primaria: state => state.Utils.primaria,
            cliente: state => state.Ente.cliente,
            retEntry: state => state.Entry.retEntry,
            qrCode: state => state.Entry.qrCode,
            colors: state => state.Ente.colors.sfondo
        }),
        calendarButtonStyle() {
            const color = this.colors[0];
            return {
                backgroundColor: color,
                color: this.getContrastText(color)
            };
        },
        event() {
            let description = `${this.entry.servizio.type}
          Codice Prenotazione: ${this.repeat.codice_prenotazione}
          Servizio: ${this.entry.servizio.type}
          Sede: ${this.entry.area.area_name}
          Indirizzo: ${this.entry.area.address}
          `;
            if (this.entry.servizio.virtuale) {
                description =
                    description +
                    `
          Link: ${this.repeat.web_conference_url} `;
            }
            description =
                description +
                `
           Link di gestione: ` +
                process.env.VUE_APP_CLIENT_URL +
                this.cliente.Codice +
                "/gestisci/show/" +
                this.repeat.codice_prenotazione +
                "?chiave=" +
                this.datiUtente[this.primaria] +
                "";
            return {
                title: this.entry.servizio.type + " " + this.cliente.Nome,
                description,
                start: this.repeat.start_time * 1000,
                location: this.entry.area.address,
                duration: [this.entry.durata, "seconds"]
            };
        }
    },
    methods: {
        downloadCalendar(type) {
            this.showCalendarDropdown = false;
            switch (type) {
                case "google":
                    window.open(google(this.event), "_blank").focus();
                    break;
                case "ics":
                    window
                        .open(
                            `${process.env.VUE_APP_SERVER_URL}/api/ics/${
                                this.repeat.repeat_id
                            }?timezone=${
                                Intl.DateTimeFormat().resolvedOptions().timeZone
                            }`,

                            "_blank"
                        )
                        .focus();

                    break;
                case "office365":
                    window.open(office365(this.event), "_blank").focus();
                    break;
                case "yahoo":
                    window.open(yahoo(this.event), "_blank").focus();
                    break;
                case "outlook":
                    window.open(outlook(this.event), "_blank").focus();
                    break;
                default:
                    return;
            }
        },
        closeCalendarDropdow() {
            this.showCalendarDropdown = false;
        }
    }
};
</script>
<style>
.btn-hover:hover {
    background-color: var(--custom-color-0) !important;
    color: var(--custom-color-text-0) !important;
}
</style>
