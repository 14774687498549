<template>
    <img :src="icon"/>
</template>
<script>
export default {
    name : 'AWalletIcon',
    computed: {
        icon() {
            return require('../../assets/appleWallet/it.svg');
        }
    },
}
</script>
